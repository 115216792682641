import {JudicialExtensionModule} from 'modules/judicial/judicial-extension.module';
import {topicosConfig as dadosBasicosTopicosConfig} from 'modules/judicial/app/main/apps/processo/processo-edit/dados-basicos/ajuda/topicos-config';
import {topicosConfig as dadosBasicosTopicosConfigTribunal} from 'modules/judicial/app/main/apps/admin/tribunal/tribunal-edit/dados-basicos/ajuda/topicos-config';
import {topicosConfig as dadosBasicosTopicosConfigOrgaoJulgador} from 'modules/judicial/app/main/apps/admin/orgao-julgador/orgao-julgador-edit/dados-basicos/ajuda/topicos-config';
import {RoleGuard} from '../../app/main/apps/role.guard';
import {widgetConfig as intimacoesWidgetConfig} from 'modules/judicial/app/main/apps/intimacoes/widget/widget-config';
import {Observable, of} from 'rxjs';
import {Atividade, Processo} from '../../@cdk/models';
import {filter, switchMap} from 'rxjs/operators';
import {navigationConverter} from '../../app/navigation/navigation';
import {CdkNavigationItem} from '../../@cdk/types';
import {GerarRelatorioJudicialModule} from "./app/main/apps/tarefas/gerar-relatorio-judicial.module";
import { CdkAtividadeFilterModule } from '../../@cdk/components/atividade/sidebars/cdk-atividade-filter/cdk-atividade-filter.module';
import { CdkTarefaGridCaixaIntegracaoModule } from './@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-caixa-integracao.module';

export const modulesConfigJudicial =
    {
        name: 'judicial',
        label: 'Judicial',
        icon: 'book',
        routes: {
            'app/main/apps/processo/processo-edit': [
                {
                    path: 'dados-basicos-judicial',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/processo/processo-edit/dados-basicos/dados-basicos-judicial.module'
                        ).then(m => m.DadosBasicosJudicialModule)
                },
                {
                    path: 'valores',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/processo/processo-edit/valores/valores.module'
                        ).then(m => m.ValoresModule)
                }
            ],
            'app/main/apps': [
                {
                    path: 'intimacoes',
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/intimacoes/intimacoes.module'
                        ).then(m => m.IntimacoesModule),
                    canActivate: [RoleGuard],
                    data: {roles: ['ROLE_COLABORADOR']}
                },
                {
                    path: 'processo-judicial',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/processo-judicial/processo-judicial.module'
                        ).then(m => m.ProcessoJudicialModule),
                    canActivate: [RoleGuard],
                    data: {roles: ['ROLE_COLABORADOR']}
                }
            ],
            'app/main/apps/admin': [
                {
                    path: 'tribunal',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/admin/tribunal/tribunal.module'
                        ).then(m => m.TribunalModule)
                },
                {
                    path: 'orgao-julgador',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/admin/orgao-julgador/orgao-julgador.module'
                        ).then(m => m.OrgaoJulgadorModule)
                },
                {
                    path: 'classe-nacional',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/admin/classe-nacional/classe-nacional.module'
                        ).then(m => m.ClasseNacionalModule)
                },
                {
                    path: 'comarca',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/admin/comarca/comarca.module'
                        ).then(m => m.ComarcaModule)
                },
                {
                    path: 'pessoa-representada',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/admin/pessoa-representada/pessoa-representada.module'
                        ).then(m => m.PessoaRepresentadaModule)
                },
                {
                    path: 'fonte-dados',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/admin/fonte-dados/fonte-dados.module'
                        ).then(m => m.FonteDadosModule)
                }
            ],
            'app/main/apps/coordenador': [
                {
                    path: ':entidadeHandle/excecao-competencia',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/coordenador/excecao-competencia/excecao-competencia.module'
                        ).then(m => m.ExcecaoCompetenciaModule)
                },
                {
                    path: ':entidadeHandle/avocacoes',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/coordenador/avocacoes/avocacoes.module'
                        ).then(m => m.AvocacoesModule)
                }
            ],
            'app/main/apps/documento/documento-edit': [
                {
                    path: 'atividade-judicial',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/documento/documento-edit/atividade/atividade-judicial.module'
                        ).then(m => m.AtividadeJudicialModule)
                }
            ],
            'app/main/apps/tarefas/tarefa-detail': [
                {
                    path: 'atividades-judicial',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/tarefas/tarefa-detail/atividades/atividades-judicial.module'
                        ).then(m => m.AtividadesJudicialModule)
                }
            ],
            'app/main/apps/tarefas': [
                {
                    path: 'atividade-bloco-judicial',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/tarefas/atividade-create-bloco/atividade-create-bloco-judicial.module'
                        ).then(m => m.AtividadeCreateBlocoJudicialModule)
                },
                {
                    path: 'atividade-registrar-bloco-judicial',
                    // tslint:disable-next-line:max-line-length
                    loadChildren: () => import(
                        'modules/judicial/app/main/apps/tarefas/atividade-registrar-bloco/atividade-registrar-bloco-judicial.module'
                        ).then(m => m.AtividadeRegistrarBlocoJudicialModule)
                }
            ]
        },
        mainMenu: [
            {
                id: 'protocolo',
                entries: [{
                    id: 'judicial',
                    title: 'Judicial',
                    type: 'item',
                    icon: 'gavel', // ALTERADO PELA PGE-RS: ícone.
                    url: '/apps/processo-judicial/criar/editar/dados-basicos-steps'
                }]
            },
            {
                id: 'tarefas',
                entries: [
                    /* ALTERADO PELA PGE-RS: comentado.
                    {
                        id: 'judicial',
                        title: 'Judicial',
                        type: 'collapsable',
                        icon: 'account_balance', // ALTERADO PELA PGE-RS: ícone.
                        hideable: true,
                        allowGroupOfHiddenChildren: false,
                        startExpanded: true, // ALTERADO PELA PGE-RS: adicionado.
                        badge: {
                            title: '0',
                            bg : '#F44336',
                            fg: '#FFFFFF'
                        },
                        badgeCompartilhada: {
                            id: 'compartilhadas_judicial',
                            badge: {
                                title: '0',
                                bg: '#fc6054',
                                fg: '#FFFFFF'
                            }
                        },
                        canHide: (item: CdkNavigationItem): boolean => {
                            let countBadges = 0;
                            countBadges = parseInt(item.badge?.title, 10);
                            if (item.badgeCompartilhada) {
                                countBadges += parseInt(item.badgeCompartilhada.badge?.title, 10);
                            }
                            return countBadges === 0;
                        },
                        children: [
                        */
                            {
                                id: 'tarefas_pendentes_judicial',
                                title: 'Judicial', // ALTERADO PELA PGE
                                type: 'item',
                                icon: 'gavel',
                                hideable: true,
                                url: '/apps/tarefas/judicial/minhas-tarefas/entrada',
                                badge: {
                                    title: '0',
                                    bg: '#F44336',
                                    fg: '#FFFFFF'
                                },
                                badgeCompartilhada: {
                                    id: 'tarefas_compartilhadas_judicial',
                                    parent: 'tarefas_pendentes_judicial',
                                    badge: {
                                        title: '0',
                                        bg: '#fc6054',
                                        fg: '#FFFFFF'
                                    }
                                },
                                canHide: (item: CdkNavigationItem): boolean => {
                                    let countBadges = 0;
                                    countBadges = parseInt(item.badge?.title, 10);
                                    if (item.badgeCompartilhada) {
                                        countBadges += parseInt(item.badgeCompartilhada.badge?.title, 10);
                                    }
                                    return countBadges === 0;
                                }
                            },
                            /* ALTERADO PELA PGE-RS: comentado.
                            {
                                id: 'tarefas_pendentes_jurídico',
                                title: 'Jurídicas',
                                type: 'item',
                                icon: 'gavel',
                                hideable: true,
                                url: '/apps/tarefas/juridico/minhas-tarefas/entrada',
                                badge: {
                                    title: '0',
                                    bg : '#F44336',
                                    fg: '#FFFFFF'
                                },
                                badgeCompartilhada: {
                                    id: 'tarefas_compartilhadas_jurídico',
                                    parent: 'tarefas_pendentes_jurídico',
                                    badge: {
                                        title: '0',
                                        bg: '#fc6054',
                                        fg: '#FFFFFF'
                                    }
                                },
                                canHide: (item: CdkNavigationItem): boolean => {
                                    let countBadges = 0;
                                    countBadges = parseInt(item.badge?.title, 10);
                                    if (item.badgeCompartilhada) {
                                        countBadges += parseInt(item.badgeCompartilhada.badge?.title, 10);
                                    }
                                    return countBadges === 0;
                                }
                            },
                            {
                                id: 'tarefas_pendentes_secretaria judiciária',
                                title: 'Apoio Jurídico', // ALTERADO PELA PGE
                                type: 'item',
                                icon: 'support', // ALTERADO PELA PGE-RS: ícone.
                                hideable: true,
                                url: '/apps/tarefas/secretariajudiciaria/minhas-tarefas/entrada',
                                badge: {
                                    title: '0',
                                    bg : '#F44336',
                                    fg: '#FFFFFF'
                                },
                                badgeCompartilhada: {
                                    id: 'tarefas_compartilhadas_secretaria judiciária',
                                    parent: 'tarefas_pendentes_secretaria judiciária',
                                    badge: {
                                        title: '0',
                                        bg: '#fc6054',
                                        fg: '#FFFFFF'
                                    }
                                },
                                canHide: (item: CdkNavigationItem): boolean => {
                                    let countBadges = 0;
                                    countBadges = parseInt(item.badge?.title, 10);
                                    if (item.badgeCompartilhada) {
                                        countBadges += parseInt(item.badgeCompartilhada.badge?.title, 10);
                                    }
                                    return countBadges === 0;
                                }
                            },
                            */
                            {
                                id: 'intimacoes_pendentes',
                                title: 'Intimações Judiciais',
                                type: 'item',
                                icon: 'account_balance', // ALTERADO PELA PGE-RS: ícone.
                                hideable: true,
                                url: '/apps/intimacoes',
                                badge: {
                                    title: '0',
                                    bg : '#F44336',
                                    fg: '#FFFFFF'
                                },
                                canHide: (item: CdkNavigationItem): boolean => {
                                    let countBadges = 0;
                                    countBadges = parseInt(item.badge?.title, 10);
                                    return countBadges === 0;
                                },
                                role: 'ROLE_DISTRIBUIDOR_JUDICIAL'
                            }
                        ]
            /* // ALTERADO PELA PGE-RS: comentado.
                    }
                ]
            */
            }
        ],
        routerLinks: {
            'app/main/apps/tarefas/tarefa-detail': {
                'atividades': {
                    judicial: 'atividades-judicial/criar'
                }
            },
            'app/main/apps/tarefas': {
                'atividades': {
                    judicial: 'atividades-judicial/criar'
                },
                'atividade-bloco': {
                    judicial: 'atividade-bloco-judicial'
                },                
                'atividade-registrar-bloco': {
                    judicial: 'atividade-registrar-bloco-judicial'
                }
            },
            'app/main/apps/documento/documento-edit': {
                'atividade': {
                    judicial: 'atividade-judicial'
                }
            }
        },
        sidebars: {
            // ALTERADO PELA PGE-RS: labels e indexes.
            'app/main/apps/processo/processo-edit/sidebars/main': [
                {
                    index: 11,
                    nome: 'Dados Judiciais', // ALTERADO PELA PGE
                    link: 'editar/dados-basicos-judicial',
                    canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                            filter(processo => !!processo),
                            switchMap((processo) => {
                                if (processo.acessoNegado || processo.especieProcesso.nome !== 'DOSSIÊ JUDICIAL') {
                                    return of(false);
                                }
                                return of(true);
                            })
                        )
                },
                {
                    index: 12,
                    nome: 'Valores Judiciais', // ALTERADO PELA PGE
                    link: 'editar/valores',
                    canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                            filter(processo => !!processo),
                            switchMap((processo) => {
                                if (processo.acessoNegado || processo.especieProcesso.nome !== 'DOSSIÊ JUDICIAL') {
                                    return of(false);
                                }
                                return of(true);
                            })
                        )
                }
            ],
            'app/main/apps/admin/sidebars/main': [
                {
                    nome: 'Tribunal',
                    icon: 'account_balance',
                    link: 'tribunal',
                    role: 'ROLE_ADMIN'
                },
                {
                    nome: 'Órgão Julgador',
                    icon: 'gavel',
                    link: 'orgao-julgador',
                    role: 'ROLE_ADMIN'
                },
                {
                    nome: 'Comarca',
                    icon: 'domain',
                    link: 'comarca',
                    role: 'ROLE_ADMIN'
                },
                {
                    nome: 'Classe Nacional',
                    icon: 'class',
                    link: 'classe-nacional',
                    role: 'ROLE_ADMIN'
                },
                {
                    nome: 'Pessoa Representada',
                    icon: 'people',
                    link: 'pessoa-representada',
                    role: 'ROLE_ADMIN'
                },
                {
                    nome: 'Fonte de Dados',
                    icon: 'library_add',
                    link: 'fonte-dados',
                    role: 'ROLE_ADMIN'
                }
            ],
            'app/main/apps/coordenador/sidebars/main#unidade': [
                {
                    nome: 'Exceção de Competência',
                    icon: 'account_balance',
                    link: 'excecao-competencia',
                    genero: 'JUDICIAL'
                },
                {
                    nome: 'Avocações',
                    icon: 'location_disabled',
                    link: 'avocacoes',
                    genero: 'JUDICIAL'
                }
            ]
        },
        components: {
            'app/main/apps/processo': [
                () => import('modules/judicial/app/main/apps/processo/processo.module').then(m => m.ProcessoModule)
            ],
            'app/main/apps/processo#converter': [
                () => import('modules/judicial/app/main/apps/processo/processo-converter.module').then(m => m.ProcessoConverterModule)
            ],
            'app/main/apps/processo/processo-capa': [
                () => import('modules/judicial/app/main/apps/processo/processo-capa/processo-capa.module').then(m => m.ProcessoCapaModule)
            ],
            'app/main/apps/processo/sidebar': [
                () => import('modules/judicial/app/main/apps/processo/sidebar/main-sidebar.module').then(m => m.ProcessoMainSidebarModule)
            ],
            'app/main/apps/tarefas/tarefa-detail': [
                () => import('modules/judicial/app/main/apps/tarefas/tarefa-detail/tarefa-detail-judicial.module').then(m => m.TarefaDetailJudicialModule)
            ],
            '@cdk/components/search-bar': [
                () => import('modules/judicial/@cdk/components/search-bar/search-bar.module').then(m => m.SearchBarModule),
            ],
            '@cdk/components/tarefa/cdk-tarefa-list/cdk-tarefa-list-item#text': [
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-list/cdk-tarefa-list-item/text-list-item-judicial.module').then(m => m.TextListItemJudicialModule)
            ],
            // ALTERADO PELA PGE-RS: comentado, para ganhar espaço.
            //'@cdk/components/tarefa/cdk-tarefa-list/cdk-tarefa-list-item#action': [
            //    () => import('modules/judicial/app/main/apps/tarefas/tarefa-list-item-action/tarefa-list-item-action.module').then(m => m.TarefaListItemActionModule),
            //],
            '@cdk/components/tarefa/cdk-tarefa-list': [
                // tslint:disable-next-line:max-line-length
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-list/cdk-tarefa-list-judicial.module').then(m => m.CdkTarefaListJudicialModule),
            ],
            '@cdk/components/tarefa/cdk-tarefa-list#header': [
                // tslint:disable-next-line:max-line-length
                () => import('modules/judicial/app/main/apps/tarefas/gerar-relatorio-judicial.module').then(m => m.GerarRelatorioJudicialModule),
            ],
            '@cdk/components/tarefa/cdk-tarefa-list#gridcolumn': [
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-jud.module').then(m => m.CdkTarefaGridJudModule),
                // ALTERADO PELA PGE-RS: supp-judicial-backend/-/issues/21
                //() => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-entidade-representada.module').then(m => m.CdkTarefaGridEntidadeRepresentadaModule),
                // ALTERADO PELA PGE-RS: adicionada coluna data disponibilização
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-data-disp.module').then(m => m.CdkTarefaGridDataDispModule),
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-entidade-representada-comunic.module').then(m => m.CdkTarefaGridEntidadeRepresentadaComunicModule),
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-fonte-dados.module').then(m => m.CdkTarefaGridFonteDadosModule),
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-numero-alternativo.module').then(m => m.CdkTarefaGridNumeroAlternativoModule),
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-tribunal.module').then(m => m.CdkTarefaGridTribunalModule),
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-orgao-julgador.module').then(m => m.CdkTarefaGridOrgaoJulgadorModule),
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-teor.module').then(m => m.CdkTarefaGridTeorModule),
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-classe-processual.module').then(m => m.CdkTarefaGridClasseProcessualModule),
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-valor-causa.module').then(m => m.CdkTarefaGridValorCausaModule),
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-grid/cdk-tarefa-grid-caixa-integracao.module').then(m => m.CdkTarefaGridCaixaIntegracaoModule),
                
            ],
            'app/main/apps/tarefas': [
                () => import('modules/judicial/app/main/apps/tarefas/tarefa-detail/tarefa-detail-judicial.module').then(m => m.TarefaDetailJudicialModule)
            ],
            '@cdk/components/tarefa/cdk-tarefa-list/cdk-tarefa-list-item': [
                // tslint:disable-next-line:max-line-length
                () => import('modules/judicial/@cdk/components/tarefa/cdk-tarefa-list/cdk-tarefa-list-item/cdk-tarefa-list-item-judicial.module').then(m => m.CdkTarefaListItemJudicialModule),
            ],
            '@cdk/components/tarefa/sidebars/cdk-tarefa-filter#position-top': [
                // tslint:disable-next-line:max-line-length
                () => import('modules/judicial/@cdk/components/tarefa/sidebars/cdk-tarefa-judicial-filter/cdk-tarefa-judicial-filter.module').then(m => m.CdkTarefaJudicialFilterModule)
            ],
            '@cdk/components/processo/sidebars/cdk-processo-filter': [
                // tslint:disable-next-line:max-line-length
                () => import('modules/judicial/@cdk/components/processo/sidebars/cdk-processo-judicial-filter/cdk-processo-judicial-filter.module').then(m => m.CdkProcessoJudicialFilterModule)
            ],
            '@cdk/components/processo/cdk-processo-autocomplete-action-filter': [
                () => import('modules/judicial/@cdk/components/search-bar/search-bar.module').then(m => m.SearchBarModule),
            ],
            '@cdk/components/processo/cdk-processo-search-autocomplete': [
                () => import('modules/judicial/@cdk/components/processo/cdk-processo-search-autocomplete/cdk-processo-search-autocomplete-entidade-representada.module').then(m => m.CdkProcessoSearchAutocompleteEntidadeRepresentadaModule),
                () => import('modules/judicial/@cdk/components/processo/cdk-processo-search-autocomplete/cdk-processo-search-autocomplete-fonte-dados.module').then(m => m.CdkProcessoSearchAutocompleteFonteDadosModule),
            ],
            'app/main/apps/board-tarefas/card-tarefa':[
                () => import('modules/judicial/app/main/apps/tarefas/tarefas-board/tarefa-judicial-card/tarefa-judicial-card.module').then(m => m.TarefaJudicialCardModule),
            ],
            'app/main/apps/processo/processo-view/external-menu-actions':[
                () => import('modules/judicial/app/main/apps/processo/process-view-actions/process-view-actions.module').then(m => m.ProcessViewActionsModule),
            ],

            '@cdk/components/atividade/sidebars/cdk-atividade-filter/cdk-atividade-filter#position-bottom': [
                () => import('modules/judicial/@cdk/components/atividade/sidebars/cdk-atividade-filter/cdk-atividade-filter.module').then(m => m.CdkAtividadeFilterModule),
            ],
            '@cdk/components/atividade/cdk-atividade-grid/cdk-atividade-extra-columns':[
                {
                    id: 'atividadeJudicial.id',
                    label: 'Houve Atividade Judicial',
                    fixed: true,
                    getContent: (atividade: Atividade) => atividade?.any?.atividadeJudicial ? 'SIM' : 'NÃO'
                },
                {
                    id: 'atividadeJudicial.peticionamentoEletronico',
                    label: 'Peticionamento Eletrônico',
                    fixed: true,
                    getContent: (atividade: Atividade) => {

                        if(!atividade?.any?.atividadeJudicial){
                            return ' -- '
                        }
                        return atividade?.any?.atividadeJudicial?.peticionamentoEletronico ? 'SIM' : 'NÃO'

                    }
                },
            ],
            'app/main/apps/tarefas/atividade-create-bloco/atividade-create-bloco#excludeModuleGenre': 'judicial',
            'app/main/apps/tarefas/operacoes-bloco/tarefas-operacoes-bloco':[
                () => import('modules/judicial/app/main/apps/processo/processo-operacoes-bloco/processo-operacoes-bloco.module').then(m => m.ProcessoOperacoesBlocoModule),                
            ],
        },
        ajuda: [
            ...dadosBasicosTopicosConfig,
            ...dadosBasicosTopicosConfigTribunal,
            ...dadosBasicosTopicosConfigOrgaoJulgador
        ],
        widgets: [
            ...intimacoesWidgetConfig,
        ],
        generosAssociados: [
            'jurídico'
        ],
        generoAffinity: {
            '@cdk/components/tarefa/cdk-tarefa-form': {
                'JUDICIAL': [
                    'JUDICIAL',
                    'JURÍDICO',
                    'SECRETARIA JUDICIÁRIA'
                ]
            },
            '@cdk/components/documento-avulso/cdk-documento-avulso-form': {
                'JUDICIAL': [
                    'JUDICIAL',
                    'JURÍDICO',
                    'SECRETARIA JUDICIÁRIA'
                ]
            },
            '@cdk/components/tarefa/cdk-tarefa-list/cdk-tarefa-list-item': {
                'JUDICIAL': [
                    'JUDICIAL',
                    'JURÍDICO',
                    'SECRETARIA JUDICIÁRIA'
                ]
            },
            'app/main/apps/tarefas': {
                'JUDICIAL': [
                    'JUDICIAL',
                    'JURÍDICO',
                    'SECRETARIA JUDICIÁRIA'
                ]
            },
            '@cdk/components/tarefa/cdk-tarefa-list/cdk-tarefa-list-item#text': {
                'JUDICIAL': [
                    'JUDICIAL',
                    'JURÍDICO',
                    'SECRETARIA JUDICIÁRIA'
                ]
            },
            '@cdk/components/tarefa/cdk-tarefa-list#header': {
                'JUDICIAL': [
                    'JUDICIAL',
                    'JURÍDICO',
                    'SECRETARIA JUDICIÁRIA'
                ]
            },
            'app/main/apps/tarefas/tarefa-detail/atividades/atividade-create': {
                'JUDICIAL': [
                    'JUDICIAL',
                    'JURÍDICO',
                    'SECRETARIA JUDICIÁRIA'
                ]
            },
        },
        navigationConverter: {
            'mainMenu' : {
                'juridico': 'jurídico',
                'secretariajudiciaria': 'secretaria judiciária'
            }
        },
        extension: JudicialExtensionModule
    };
