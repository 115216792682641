import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RouterControlService {

    routerState: any;
    router: Router;

    constructor(routerState, router) {
       this.routerState = routerState;
       this.router = router;
    }

    redirect(route: string): void {
        this.router.navigate([route]).then();
    }
}
