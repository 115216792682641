import * as moment from 'moment';
import {Exclude, Transform, Type} from 'class-transformer';
import {Processo, DadosFormulario, TipoSolicitacaoAutomatizada, Usuario, Setor, Dossie, Documento} from '@cdk/models';
import {StatusSolicitacaoAutomatizadaE} from '../enums/status-solicitacao-automatizada.enum';

export class SolicitacaoAutomatizada {

    id?: number;
    uuid?: string;

    @Type(() => Processo)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    processo?: Processo;

    @Type(() => DadosFormulario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    dadosFormulario?: DadosFormulario;

    dadosCumprimento?: string;

    @Type(() => TipoSolicitacaoAutomatizada)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    tipoSolicitacaoAutomatizada?: TipoSolicitacaoAutomatizada;

    status?: StatusSolicitacaoAutomatizadaE;

    statusExibicao?: string;

    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    usuarioResponsavel?: Usuario;

    @Type(() => Setor)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    setorResponsavel?: Setor;

    @Type(() => Documento)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    resultadoSolicitacao?: Documento;

    observacao?: string;

    urgente: boolean = false;

    dossiesNecessarios?: string;

    analisesDossies?: string;



    @Type(() => Dossie)
    dossies?: Dossie[];

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    criadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format() : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    criadoEm?: moment.Moment;

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    atualizadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format() : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    atualizadoEm?: moment.Moment;

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    apagadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format() : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    apagadoEm?: moment.Moment;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.processo = null;
        this.dadosFormulario = null;
        this.tipoSolicitacaoAutomatizada = null;
        this.status = null;
        this.statusExibicao = null;
        this.usuarioResponsavel = null;
        this.setorResponsavel = null;
        this.observacao = null;
        this.urgente = false;
        this.dossiesNecessarios = null;
        this.analisesDossies = null;
        this.dossies = [];
        this.criadoPor = null;
        this.criadoEm = null;
        this.atualizadoPor = null;
        this.atualizadoEm = null;
        this.apagadoPor = null;
        this.apagadoEm = null;
    }
}
